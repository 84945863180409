import React , { useState } from 'react';
import HomePage from '../images/HomePage.mp4';
import HomeSide from '../images/HomeSide.mp4';
import HomeMain from '../images/HomeMain.png';
import HomeText from '../images/HomeText.png';
import "./Home.css";


const Home = () => {

  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const handleVideoEnd = () => {
    setIsVideoEnded(true);

  };
  return (
    <>
      { <div style={{ marginTop: '80px' }}>
        <div className="image-container">
        {!isVideoEnded ? (
          <video width="100%" autoPlay muted 
          controlsList="nodownload" 
          onContextMenu={(e) => e.preventDefault()}
          onEnded={handleVideoEnd}  // Trigger when video ends
          >
            <source src={HomePage} type="video/mp4" className="background-image"/>
          </video>
          ) : (
            <img src={HomeMain} alt="HomeImage" 
            className="background-image" 
            width="100%" 
            onContextMenu={(e) => e.preventDefault()}/> 
          )}
        </div>
      </div> }

      {/* <div className="text-center mt-5 pt-5">
        <h2> <span> Welcome </span> &nbsp; </h2>
      </div> */}

      {/* <div className='Battery-Container mt-5  mb-5'>
          <div className="video-container">
            <video className="video-side" autoPlay loop muted controlsList="nodownload" onContextMenu={(e) => e.preventDefault()}> 
              <source src={HomeSide} type="video/mp4"/>
            </video>
          </div>
          <div className="image-container  mb-5">
            <img src={HomeText} alt="Battery" className="Battery" onContextMenu={(e) => e.preventDefault()}/>
          </div>
      </div> */}
    </>
  )
}

export default Home;
