import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-scroll';
import '../App.css';
import logo from '../images/Logo_VRJ.png';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('home'); // Set default active tab
  const menuRef = useRef(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const scrollOffset = -130; // Changed from -100 to -150 to show full captions

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && event.target.id !== 'menu-btn') {
      setMenuOpen(false);
    }
  };

  const handleLinkClick = (tab) => {
    console.log('Clicking:', tab);
    setActiveTab(tab);
    setMenuOpen(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollTop(true);
    } else {
      setShowScrollTop(false);
    }

    // Update this offset to match
    const offset = 130; // Changed from 100 to 150
    const scrollPosition = window.scrollY + offset;

    // Add console.log to debug scroll positions
    const sections = ['home', 'about', 'service', 'contact', 'gallery'];
    sections.forEach((section) => {
      const sectionElement = document.getElementById(section);
      if (sectionElement) {
        const sectionOffsetTop = sectionElement.offsetTop;
        const sectionHeight = sectionElement.offsetHeight;

        if (scrollPosition >= sectionOffsetTop && scrollPosition < sectionOffsetTop + sectionHeight) {
          setActiveTab(section);
        }
      }
    });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="header">
      <div className="container">
        <a href="/#" className="logo">
          <img src={logo} alt="logo" />         
        </a>
        <nav ref={menuRef} className={`navbar ${menuOpen ? 'active' : ''}`}>
          <Link 
            to="home" 
            smooth={true} 
            duration={500} 
            offset={scrollOffset}
            onClick={() => handleLinkClick('home')} 
            className={activeTab === 'home' ? 'active' : ''}
          >
            Home
          </Link>
          <Link 
            to="about" 
            smooth={true} 
            duration={500} 
            offset={scrollOffset}
            onClick={() => handleLinkClick('about')} 
            className={activeTab === 'about' ? 'active' : ''}
          >
            About Us
          </Link>
          <Link 
            to="service" 
            smooth={true} 
            duration={500} 
            offset={scrollOffset}
            onClick={() => handleLinkClick('service')} 
            className={activeTab === 'service' ? 'active' : ''}
          >
            Services
          </Link>
          <Link 
            to="gallery" 
            smooth={true} 
            duration={500} 
            offset={scrollOffset}
            onClick={() => handleLinkClick('gallery')} 
            className={activeTab === 'gallery' ? 'active' : ''} 
          >
            Gallery
          </Link>
          <Link 
            to="contact" 
            smooth={true} 
            duration={500} 
            offset={scrollOffset}
            onClick={() => handleLinkClick('contact')} 
            className={activeTab === 'contact' ? 'active' : ''}
          >
            Contact Us
          </Link>         
        </nav>
        <div className="icons">
          <div className="fas fa-bars" id="menu-btn" onClick={handleMenuClick}></div>
        </div>
      </div>
      {showScrollTop && (
        <button className="scroll-top" onClick={scrollToTop}>
          <i className="fas fa-arrow-up"></i>
        </button>
      )}
    </section>
  );
};

export default Header;
