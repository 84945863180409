import React,{ useState }from 'react';
import AboutPage from '../images/AboutPage.mp4';
import AboutMain from '../images/AboutMain.png';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaBullseye, FaEye } from 'react-icons/fa'; // Icons for Mission and Vision
import './About.css';

const About = () => {

  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const handleVideoEnd = () => {
    setIsVideoEnded(true);

  };
  // State to track which card is clicked
  const [showMission, setShowMission] = useState(false);
  const [showVision, setShowVision] = useState(false);

  // Toggle function for showing mission/vision content
  const toggleMission = () => setShowMission(!showMission);
  const toggleVision = () => setShowVision(!showVision);

  return (
    <>
      {/* <div style={{ marginTop: '10px' }}>
        <div className="image-container">
        {!isVideoEnded ? (
          <video width="100%" autoPlay muted 
          controlsList="nodownload" 
          onContextMenu={(e) => e.preventDefault()}
          onEnded={handleVideoEnd} >
            <source src={AboutPage} type="video/mp4" className="background-image"/>
          </video>
          ) : (
            <img src={AboutMain} alt="AboutImage" 
            className="background-image" 
            width="100%" 
            onContextMenu={(e) => e.preventDefault()}/> 
          )}
        </div>
    </div> */}

    <div className='Head mt-1 pt-1'>
            <h1 class="heading"><span> About </span> &nbsp; Us </h1>
      </div>
    <Container className="my-5 pt-2">
      <Row className="justify-content-center">
        {/* Mission Card */}
        <Col xs={12} md={6} lg={4} className="mb-4">
          <Card className="text-center shadow-sm mission-vision-card">
            <Card.Body 
              onMouseEnter={() => setShowMission(true)}
              onMouseLeave={() => setShowMission(false)}
              style={{ cursor: 'pointer' }}
            >
              {!showMission ? (
                <>
                  <FaBullseye size={50} className="mission-vision-icon" />
                  <Card.Title className="mt-3">MISSION</Card.Title>
                </>
              ) : (
                <Card.Text>
                  Our mission is to provide tailored, high-quality engineering
                  solutions that help industries improve efficiency, reliability,
                  and safety.
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>

        {/* Vision Card */}
        <Col xs={12} md={6} lg={4} className="mb-4">
          <Card className="text-center shadow-sm mission-vision-card">
            <Card.Body 
              onMouseEnter={() => setShowVision(true)}
              onMouseLeave={() => setShowVision(false)}
              style={{ cursor: 'pointer' }}
            >
              {!showVision ? (
                <>
                  <FaEye size={50} className="mission-vision-icon" />
                  <Card.Title className="mt-3">VISION</Card.Title>
                </>
              ) : (
                <Card.Text>
                  We aim to support our clients through every phase of their
                  project, ensuring successful outcomes from initial design to
                  final commissioning.
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default About;
