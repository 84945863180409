import React from 'react';
// import { Link } from 'react-scroll'; 
// import { Routes, Route,Navigate } from 'react-router-dom';
import './App.css';
import Home from './components/Home'; 
import About from './components/About'; 
import Service from './components/Service'; 
import Contact from './components/Contact'; 
import Header from './components/Header';
import Footer from './components/Footer';
// import Gallery from './components/Gallery';

function App() {
  return (
  
      <div className="App">
        
        <Header />

      {/* Sections */}
      <section id="home">
        <Home />
      </section>

      <section id="about">
        <About />
      </section>
      
      <section id="service">
        <Service />
      </section>      
     
      {/* <section id="gallery">
        <Gallery />
      </section> */}

      <section id="contact">
        <Contact />
      </section>

      {/* Footer */}
      <Footer />
      </div>
    
  );
}

export default App;
