import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import ServicePage from '../images/ServicePage.mp4';
import ServiceMain from '../images/ServiceMain.png';
import './Service.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Service = () => {
  const [flippedCards, setFlippedCards] = useState(Array(6).fill(false));

  const services = [
    {
      title: "Electrical System Design",
      description: "We create efficient electrical layouts tailored to your facility's needs, ensuring optimal performance and compliance. Our designs prioritize safety and reliability for seamless operations.",
      icon: "fas fa-drafting-compass",
    },
    {
      title: "Reliability & Plant Studies",
      description: "Our in-depth studies assess system performance and identify potential failure points. We provide actionable insights to enhance reliability and minimize downtime.",
      icon: "fas fa-chart-line",
    },
    {
      title: "Standards Compliance",
      description: "We ensure your plant adheres to all relevant regulations and industry standards. Our thorough audits help you avoid penalties and maintain a strong reputation.",
      icon: "fas fa-clipboard-check",
    },
    {
      title: "Project Management Support",
      description: "Our project management services streamline execution from start to finish, ensuring timelines and budgets are met. We facilitate effective communication among all stakeholders for successful project outcomes.",
      icon: "fas fa-tasks",
    },
    {
      title: "Interlock Design",
      description: "We specialize in designing interlock systems that enhance safety and operational reliability. Our custom solutions prevent hazardous conditions and ensure equipment operates safely.",
      icon: "fas fa-lock",
    },
    {
      title: "Licensed Electrical Worker (LEW)",
      description: "Our team of Licensed Electrical Workers guarantees safe and compliant electrical installations. With their expertise, your projects are handled with the highest quality and safety standards.",
      icon: "fas fa-shield-halved",
    },
  ];

  const [isVideoEnded, setIsVideoEnded] = useState(false);

  const handleVideoEnd = () => {
    setIsVideoEnded(true);

  };

  const handleCardClick = (index, isEntering) => {
    setFlippedCards(prevState => {
      const newState = [...prevState];
      newState[index] = isEntering;
      return newState;
    });
  };

  return (
    <>
      {/* <div style={{ marginTop: '10px' }}>
        <div className="image-container">
        {!isVideoEnded ? (
          <video width="100%" autoPlay muted
          controlsList="nodownload" 
          onContextMenu={(e) => e.preventDefault()}
          onEnded={handleVideoEnd} >
            <source src={ServicePage} type="video/mp4" className="background-image" />
          </video>
          ) : (
            <img src={ServiceMain} alt="ServiceImg" 
            className="background-image" 
            width="100%" 
            onContextMenu={(e) => e.preventDefault()}/> 
          )}
        </div>
      </div> */}
      <Container className="mt-1">
        <Row className="text-center mb-4">
          <Col>
            <h2>Our <span>Services</span></h2>
          </Col>
        </Row>
        <Row className="mt-2">       
          {services.map((service, index) => (
            <Col md={4} sm={6} xs={12} className="mb-4" key={index}>
              <div
                className={`service-card-container ${flippedCards[index] ? 'flipped' : ''}`}
                onMouseEnter={() => handleCardClick(index, true)}
                onMouseLeave={() => handleCardClick(index, false)}
                onTouchStart={() => handleCardClick(index, true)}
                onTouchEnd={() => handleCardClick(index, false)}
              >                
                <div className="service-card">                  
                  <div className="service-front">
                    <div className="icon-circle">
                      <i className={service.icon}></i>
                    </div>
                    <h5>{service.title}</h5>
                  </div>
                  <div className="service-back">
                    <p>{service.description}</p>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default Service;
