import React, { useState }  from 'react';
import axios from 'axios';
import ContactPage from '../images/ContactPage.mp4';
import ContactMain from '../images/ContactMain.png';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './Contact.css'; // Optional CSS for custom styling


const Contact = () => {

   // Define state variables for the form inputs
   const [name, setName] = useState('');
   const [email, setEmail] = useState('');
   const [phone, setPhone] = useState('');
   const [message, setMessage] = useState('');
   const [error, setError] = useState('');
 
   const handleSubmit = (e) => {
     e.preventDefault(); // Prevent the default form submission behavior
 
     // Basic validation logic
     if (!name || !email || !phone || !message) {
       setError('Please fill out all the fields before submitting.');
       return; // Stop submission if fields are empty
     }
 
     // If validation passes, clear error and proceed
     setError('');
    //alert('Form submitted successfully!');
     
     // Create FormData to send to PHP
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('message', message);
    
    // POST request to PHP script
    axios.post('http://localhost//Savi/sendMail.php', formData)
      .then(response => {
        if (response.data.trim() === 'success') {
          alert('Form submitted successfully!');
          setName('');
          setEmail('');
          setPhone('');
          setMessage('');
        } else {
          setError('There was an error sending your message. Please try again later.');
        }
      })
      .catch(error => {
        setError('Error sending the message. Please try again later.');
      });
  };
  

  
  const [isVideoEnded, setIsVideoEnded] = useState(false);
  const handleVideoEnd = () => {
    setIsVideoEnded(true);

  };
  return (
    <>
      {/* <div style={{ marginTop: '10px' }}>
        <div className="image-container">
        {!isVideoEnded ? (
          <video width="100%" autoPlay muted 
          controlsList="nodownload"
          onContextMenu={(e) => e.preventDefault()}
          onEnded={handleVideoEnd}>
            <source src={ContactPage} type="video/mp4" className="background-image"/>
          </video>
          ) : (
            <img src={ContactMain} alt="ContactImg" 
            className="background-image" 
            width="100%" 
            onContextMenu={(e) => e.preventDefault()}/> 
          )}
        </div>
    </div>*/}

    <Row className="text-center mt-1">
        <Col>
          <h2> <span> Contact </span> &nbsp;Us </h2>
        </Col>
      </Row> 

    <Container className="contact-container mt-2">
      <Row className="justify-content-center align-items-center">
        {/* Left Side - Contact Form */}
        {/*<Col md={6} xs={12} className="contact-form">
          <div className="form-card">
            <Form onSubmit={handleSubmit}>
            {error && <p className="text-danger">{error}</p>}
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name"value={name}
                  onChange={(e) => setName(e.target.value)} />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" value={email}
                  onChange={(e) => setEmail(e.target.value)} />
              </Form.Group>

              <Form.Group controlId="formPhone" className="mt-3">
                <Form.Label>Telephone</Form.Label>
                <Form.Control type="text" placeholder="Enter your phone number" value={phone}
                  onChange={(e) => setPhone(e.target.value)} />
              </Form.Group>

              <Form.Group controlId="formMessage" className="mt-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="Enter your message"  value={message}
                  onChange={(e) => setMessage(e.target.value)} />
              </Form.Group>

              <Button className="mt-4" variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </div>
        </Col>
      */}
        {/* Right Side - Contact Info */}
        <Col md={6} xs={12} className="contact-info text-center">
          <div className="contact-card">
            {/* <div className="icon-circle mb-3">
             <a href="mailto:contact@vrjsolutions.com"><i className="fas fa-envelope fa-3x mail"></i></a> 
            </div> */}
            {/* <h2>Contact Us</h2> */}
            {/* <p>Let's Start Creating! Fill out the form on the left and send us a message.</p>
            <p>We look forward to hearing from you!</p> */}
             <h4 className="mt-4">VRJ Solutions Pte. Ltd.</h4>
            <h4 className="mt-4">7500A, Beach Road, #14-302,</h4>
            <h4 className="mt-4">The Plaza, Singapore - 199591</h4>
            <h4 className="mt-4">Call us : +65 91544181</h4>
            {/* <p className="contact-phone"> +65 91544181</p> */}
            <h3 className="mt-4">Email us : contact@vrjsolutions.com</h3>
            
          </div>
        </Col>
      </Row>
    </Container>
    </>
  )
}

export default Contact;
